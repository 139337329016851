@font-face {
  font-family: "Montserrat";
  src: local("Montserrat-Bold"),
    url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: normal;
}

a {
  text-decoration: none !important;
}

p {
  overflow-wrap: break-word;
}

.background {
  height: 100%;
  background: #ECECEC;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

.main {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding-left: 260px;
}

@media only screen and (max-width: 1250px) {
  .main {
    padding-left: 65px;
  }
}